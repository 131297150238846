.prompt-box-8w {
  // default 8th wall motion access prompt
  z-index: 9999999999 !important;
}

#loadingContainer {
  // default 8th wall loading screen
  display: none;
}

#loadingscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  flex-direction: column;
  height: 100%;
  background-color: rgba(255, 255, 255, 1);
  z-index: 999999;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 80%;
  }

  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0 auto;
    width: 50%;
    .text {
      color: black;
      font-size: 1.5rem;
      width: 100%;
      font-weight: 600;
      text-align: center;
    }
    .button {
      padding: 5px 10px;
      border: 1px solid black;
    }
  }

  @keyframes rotate {
    0% {
      transform: rotateX(-37.5deg) rotateY(45deg);
    }
    50% {
      transform: rotateX(-37.5deg) rotateY(405deg);
    }
    100% {
      transform: rotateX(-37.5deg) rotateY(405deg);
    }
  }

  .cube-container {
    margin-top: 100px;
    height: 200px;
    width: 100%;
  }

  .cube,
  .cube * {
    position: absolute;
    width: 100px;
    height: 100px;
    left: 0;
    right: 0;
    margin: 0 auto;
  }

  .sides {
    animation: rotate 3s ease infinite;
    animation-delay: 0.8s;
    transform-style: preserve-3d;
    transform: rotateX(-37.5deg) rotateY(45deg);
  }

  .cube .sides * {
    box-sizing: border-box;
    background-color: rgba(255, 255, 255, 1);
    border: 10px solid black;
  }
  .cube .sides .bottom {
    animation: bottom-animation 3s ease infinite;
    animation-delay: 0ms;
    transform: rotateX(-90deg) translateZ(100px);
    animation-fill-mode: forwards;
    transform-origin: 50% 50%;
  }
  @keyframes bottom-animation {
    0% {
      opacity: 1;
      transform: rotateX(-90deg) translateZ(100px);
    }
    20% {
      opacity: 1;
      transform: rotateX(-90deg) translateZ(50px);
    }
    70% {
      opacity: 1;
      transform: rotateX(-90deg) translateZ(50px);
    }
    90% {
      opacity: 1;
      transform: rotateX(-90deg) translateZ(100px);
    }
    100% {
      opacity: 1;
      transform: rotateX(-90deg) translateZ(100px);
    }
  }
  .cube .sides .front {
    animation: front-animation 3s ease infinite;
    animation-delay: 100ms;
    transform: rotateY(0deg) translateZ(100px);
    animation-fill-mode: forwards;
    transform-origin: 50% 50%;
  }
  @keyframes front-animation {
    0% {
      opacity: 1;
      transform: rotateY(0deg) translateZ(100px);
    }
    20% {
      opacity: 1;
      transform: rotateY(0deg) translateZ(50px);
    }
    70% {
      opacity: 1;
      transform: rotateY(0deg) translateZ(50px);
    }
    90% {
      opacity: 1;
      transform: rotateY(0deg) translateZ(100px);
    }
    100% {
      opacity: 1;
      transform: rotateY(0deg) translateZ(100px);
    }
  }
  .cube .sides .back {
    animation: back-animation 3s ease infinite;
    animation-delay: 100ms;
    transform: rotateY(-180deg) translateZ(100px);
    animation-fill-mode: forwards;
    transform-origin: 50% 50%;
  }
  @keyframes back-animation {
    0% {
      opacity: 1;
      transform: rotateY(-180deg) translateZ(100px);
    }
    20% {
      opacity: 1;
      transform: rotateY(-180deg) translateZ(50px);
    }
    70% {
      opacity: 1;
      transform: rotateY(-180deg) translateZ(50px);
    }
    90% {
      opacity: 1;
      transform: rotateY(-180deg) translateZ(100px);
    }
    100% {
      opacity: 1;
      transform: rotateY(-180deg) translateZ(100px);
    }
  }
  .cube .sides .left {
    animation: left-animation 3s ease infinite;
    animation-delay: 100ms;
    transform: rotateY(-90deg) translateZ(100px);
    animation-fill-mode: forwards;
    transform-origin: 50% 50%;
  }
  @keyframes left-animation {
    0% {
      opacity: 1;
      transform: rotateY(-90deg) translateZ(100px);
    }
    20% {
      opacity: 1;
      transform: rotateY(-90deg) translateZ(50px);
    }
    70% {
      opacity: 1;
      transform: rotateY(-90deg) translateZ(50px);
    }
    90% {
      opacity: 1;
      transform: rotateY(-90deg) translateZ(100px);
    }
    100% {
      opacity: 1;
      transform: rotateY(-90deg) translateZ(100px);
    }
  }
  .cube .sides .right {
    animation: right-animation 3s ease infinite;
    animation-delay: 100ms;
    transform: rotateY(90deg) translateZ(100px);
    animation-fill-mode: forwards;
    transform-origin: 50% 50%;
  }
  @keyframes right-animation {
    0% {
      opacity: 1;
      transform: rotateY(90deg) translateZ(100px);
    }
    20% {
      opacity: 1;
      transform: rotateY(90deg) translateZ(50px);
    }
    70% {
      opacity: 1;
      transform: rotateY(90deg) translateZ(50px);
    }
    90% {
      opacity: 1;
      transform: rotateY(90deg) translateZ(100px);
    }
    100% {
      opacity: 1;
      transform: rotateY(90deg) translateZ(100px);
    }
  }
}
