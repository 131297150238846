.viewer-menu {
  position: absolute;
  top: 20px;
  right: 25px;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  //padding: 5px;
  z-index: 9999999999;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
  &:active {
    i {
      opacity: 1;
    }
  }
  i {
    color: #fff;
    opacity: 0.3;
    font-size: 40px;
  }
}

.ant-dropdown {
  .ant-dropdown-menu-item {
    padding: 10px;
  }
}
