.product-banner-container {
  z-index: 99999999999;
  position: fixed;
  width: 100%;
  transition: all 0.2s ease;
  bottom: -170px;
  left: 0;
  flex-direction: "column";
  height: 130px;
  &.product {
    bottom: -130px;
    height: 195px;
  }
  &.mounted {
    bottom: 0px;
  }
  &__inner {
    &__top {
      display: flex;
      &__right {
        // height: auto;
      }
    }
    width: 100%;
    padding: 10px;
    background-color: white;
    border-top-left-radius: 5%;
    border-top-right-radius: 5%;
    position: relative;
    img {
      height: 50px;
    }
    &__right {
      // height: auto;
      width: 100%;
      padding: 0px 3px;
      .product_banner_name {
        color: black;
        font-weight: bold;
        font-size: 18px;
        margin: 0;
      }
      .product_banner_price {
        color: black;
        margin: 0;
        padding: 0;
        font-size: 14px;
        font-weight: 300;
        font-style: italic;
      }
    }
    .return-to-store {
      color: #fff;
      text-align: center;
      padding-bottom: 20px;
      .button {
        background-color: rgb(142, 142, 147);
        display: inline-block;
        padding: 10px 20px;
        font-weight: bold;
      }
    }
    .inner_buttons_container {
      width: 100%;
      text-align: center;
      flex: 1;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .small-button {
        flex: 1;
        background: rgb(142, 142, 147);
        border-radius: 3px;
        color: #fff;
        font-weight: bold;
        padding: 5px 0px;
        margin: 0 5px;
        user-select: none;
        &.disabled {
          opacity: 0.2;
          &:active {
            background: rgb(142, 142, 147);
          }
        }
        &:active {
          background: rgb(141, 185, 203);
        }
        &.remove-button {
          border: 1px solid red;
          &:active {
            background: rgb(255, 0, 0);
          }
        }
      }
    }
  }
}
