.ant-modal-mask {
  z-index: 999999999999 !important;
}

.ant-modal-wrap {
  z-index: 999999999999 !important;
}

.ant-modal-content {
  background-color: #000000d9 !important;
}
