@keyframes joystickMove-animation {
  0% {
    top: 0;
    left: 0;
  }
  33% {
    top: -50vw;
    left: 0;
  }
  66% {
    top: 50vw;
    left: 0;
  }
  100% {
    top: 0;
    left: 0;
  }
}
@keyframes joystickRotate-animation {
  0% {
    top: 0;
    left: 0;
  }
  33% {
    top: 0;
    left: -30vw;
  }
  66% {
    top: 0;
    left: 30vw;
  }
  100% {
    top: 0;
    left: 0;
  }
}
@keyframes text-animation {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes setting-animation {
  0% {
    opacity: 0.3;
    transform: scale(1);
  }
  50% {
    opacity: 1;
    transform: scale(1.3);
  }
  100% {
    opacity: 0.3;
    transform: scale(1);
  }
}
@keyframes end-animation {
  0% {
    opacity: 0.5;
    transform: scale(1);
  }
  50% {
    opacity: 1;
    transform: scale(1.3);
  }
  100% {
    opacity: 0.5;
    transform: scale(1);
  }
}
.instruction-container {
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 999999999;
  background-color: black;
  height: 100vh;
  width: 100vw;
  transition: all 0.1s ease;
  &.hideBg {
    display: none;
  }
  .audio-instruction {
    lottie-player {
      position: absolute;
    }
    .instruction-text {
      padding-right: 20px;
      padding-left: 20px;
      text-align: center;
      margin-top: 10vh;
      opacity: 1;
      font-family: sans-serif;
      user-select: none;
    }
  }
  .joystick-instruction {
    position: absolute;
    bottom: 30%;
    .joystick-animation-inner {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100vw;
      .joystick-animation-offset {
        position: absolute;
        opacity: 0.5;
        display: block;
        z-index: 999;
        transition: opacity 250ms ease 0s;
        top: 0px;
        left: 57%;
        .joystickMove-animation {
          position: absolute;
          display: block;
          margin-left: -75px;
          margin-top: -75px;
          top: 0;
          width: 105px;
          height: 105px;
          border: 2px solid greenyellow;
          border-radius: 50px;
          animation-name: joystickMove-animation;
          animation-duration: 4s;
          animation-delay: 0.5s;
        }
        .joystickRotate-animation {
          position: absolute;
          display: block;
          margin-left: -75px;
          margin-top: -75px;
          top: 0;
          width: 105px;
          height: 105px;
          border: 2px solid greenyellow;
          border-radius: 50px;
          animation-name: joystickRotate-animation;
          animation-duration: 4s;
          animation-delay: 0.5s;
        }
      }
    }
    h1 {
      position: absolute;
      width: 100%;
      text-align: center;
      margin-top: -30%;
      opacity: 0;
      font-size: x-large;
      animation-name: text-animation;
      animation-duration: 4s;
      animation-delay: 0.5s;
      user-select: none;
    }
    h2 {
      position: absolute;
      width: 100%;
      text-align: center;
      margin-top: -30%;
      opacity: 0;
      font-size: x-large;
      animation-name: text-animation;
      animation-duration: 4s;
      animation-delay: 0.5s;
      user-select: none;
    }
  }
  .setting-instruction {
    display: flex;
    position: absolute;
    align-items: baseline;
    top: 10px;
    right: 25px;
    i {
      border-radius: 15px;
      color: #fff;
      opacity: 1;
      font-size: 40px;
      animation-name: setting-animation;
      animation-duration: 2s;
      animation-iteration-count: infinite;
    }
    .instruction-text {
      font-family: sans-serif;
      transform: translate(-15px, -9px);
      user-select: none;
    }
  }
  .end {
    h1 {
      padding-right: 20px;
      padding-left: 20px;
      text-align: center;
      margin-top: 10vh;
      opacity: 1;
      animation-name: end-animation;
      animation-duration: 4s;
      animation-iteration-count: infinite;
      user-select: none;
    }
  }
}
.skip-button {
  position: fixed;
  z-index: 9999999999;
  bottom: 8vw;
  right: 8vw;
  width: 30px;
  height: 30px;
  border-radius: 20px;
  h1 {
    text-align: center;
    user-select: none;
  }
}
