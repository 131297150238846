.audio-button {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  //padding: 5px;
  z-index: 9999999999;
  display: flex;
  align-items: center;
  justify-content: center;
  &:active {
    background-color: rgb(84, 122, 123);
  }
  i {
    color: #fff;
    font-size: 20px;
  }
}
