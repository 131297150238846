@import "~antd/dist/antd.dark.css";

.ant-tooltip {
  z-index: 999999;
}
.ant-dropdown {
  z-index: 99999999999;
}
.ant-dropdown-menu {
  background-color: transparent;
}
.ant-dropdown-menu-item {
  padding: 0;
}
#hammertouches {
  width: 100vw;
  height: 100vh;
  z-index: 99999;
}

body {
  // background: transparent !important;
  overflow: hidden;
  position: fixed;
}

#logo {
  .logo1 {
    z-index: 1;
  }
  .logo2 {
    z-index: 1;
  }
}

canvas {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 2000 !important;
}
