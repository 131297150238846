.product-hud {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  z-index: 99999;

  .hud-element {
    z-index: 999999999;
  }
  .control-buttons {
    position: absolute;
    right: 15px;
    top: 80px;
    display: flex;
    flex-direction: column;
    .showroom-button {
      margin-bottom: 15px;
      user-select: none;
    }
  }
}
