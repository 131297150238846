.showroom-button {
  display: inline-flex;
  justify-content: flex-end;
  .icon-button {
    width: 40px;
    height: 40px;
    border-radius: 25px;
    background: rgb(53, 53, 53);
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    &:active {
      background: #0b7eb0;
    }
    &.active {
      border: solid 2px #0badf1;
      box-shadow: 0 0 7px #0badf1;
    }
  }
  .slider-wrapper {
    width: 0px;
    transition: all 0.2s ease;
    opacity: 0;
    display: inline-flex;
    align-items: center;
    margin-right: 5px;
    &.active {
      opacity: 1;
      width: 150px;
    }
    .inner-wrapper {
      background: rgb(53, 53, 53);
      width: 100%;
      padding: 5px;
      padding-left: 10px;
      .ant-slider {
        margin: 0;
        width: 96%;
        .ant-slider-rail {
          background-color: #fff;
        }
        .ant-slider-track {
          background-color: #fff;
        }
        .ant-slider-handle {
          border: solid 2px #0badf1;
          box-shadow: 0 0 5px #0badf1;
        }
      }
    }
  }
}
