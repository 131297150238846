:host(bitreel-viewer) {
  // font-weight: bold;
  font-family: sans-serif;
}

.showroom-hud {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  z-index: 99999;
  .hud-element {
    z-index: 999999999;
  }
  .control-buttons-instructions {
    opacity: 0;
    transition: all 0.5s ease;
    position: absolute;
    top: 90px;
    right: 60px;
    padding: 10px;
    text-align: center;
    background: rgb(53, 53, 53);
    color: #fff;
    width: 200px;
    &.mounted {
      opacity: 1;
    }
  }
  .control-buttons {
    position: absolute;
    right: 15px;
    top: 80px;
    display: flex;
    flex-direction: column;
    .showroom-button {
      margin-bottom: 15px;
      user-select: none;
    }
  }
}
