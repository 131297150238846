.logo {
  position: fixed;
  bottom: 20px;
  right: 25px;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  opacity: 0.3;
  z-index: 9999999999;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
  }
}
