.product-carousel-container {
  z-index: 99999999999;
  position: fixed;
  width: 100%;
  transition: all 0.2s ease;
  bottom: -200px;
  left: 0;
  flex-direction: "column";
  height: 200px;
  &.mounted {
    bottom: 0px;
  }
  &__inner {
    height: 100%;
    background-color: white;
    border-top-left-radius: 5%;
    border-top-right-radius: 5%;
    display: flex;
    white-space: nowrap;
    overflow: auto;
    .product-card {
      width: 100px !important;
      margin: 5px 20px;
      display: inline-block;
      margin-top: 20px;
      height: 80px;
      display: inline-flex;
      align-items: flex-end;
      justify-content: center;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      border-radius: 5%;
      border: 1px solid black;
      img {
        width: 100%;
      }
      h3 {
        text-align: center;
        color: rgb(225, 216, 204);
        font-weight: bold;
        background: grey;
        font-size: 14px;
        width: 100%;
        transform: translateY(34px);
      }
    }
  }
}
