.actions-menu-container {
  z-index: 9999999;
  position: absolute;
  bottom: 30%;
  width: 100vw;
  transition: all 0.3s ease;
  opacity: 0;
  display: flex;
  justify-content: space-between;
  &.mounted {
    opacity: 1;
  }
  &__inner {
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(39, 39, 39);
    p {
      color: white;
      font-weight: bold;
      font-size: 18px;
      margin: 0;
      padding: 8px;
    }
  }
}
