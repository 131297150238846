.tipimage {
  position: fixed;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  transition: all 0.5s ease;
  opacity: 0;
  z-index: 9999999999;
  pointer-events: none;
  transform: translateY(-110px);
  &.mounted {
    opacity: 1;
  }
  lottie-player {
    position: absolute;
  }
  h2 {
    text-align: center;
    margin-top: 65vh;
  }
}
