.overylay-message-container {
  z-index: 99999999999;
  position: fixed;
  bottom: 80%;
  width: 100vw;
  transition: all 0.3s ease;
  opacity: 0;
  &.mounted {
    opacity: 1;
  }
  &__inner {
    padding: 10px;
    background-color: rgb(39, 39, 39);
    display: flex;
    align-items: center;
    justify-content: center;
    p {
      color: white;
      text-align: center;
    }
  }
}
