.add-product-button-container {
  position: fixed;
  bottom: 90px;
  right: 30px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  transition: all 0.2s ease;
  z-index: 9999999999;
  .add-product-button {
    color: black;
    font-weight: bold;
    font-size: 25px;
    h1 {
      position: absolute;
      right: 50px;
      margin-top: 10px;
      width: 100px;
      font-size: medium;
      text-align: center;
    }
  }
  &.move-up {
    bottom: 230px;
  }
}
